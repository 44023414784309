var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
var subsiteHijackMainNav = ['school-of-system-change'];
var subsiteHijackMainLogoClickDestination = ['school-of-system-change'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// // Home Feeds Events - Slick
// if (window.matchMedia("(min-width: 577px)").matches) {
//   $(".homeFeed.InternationalProjectsFeed").find('.feedList').slick({
//     slidesToShow: 1,
//     centerMode: true,
//     slidesToScroll: 1,
//     arrows: true,
//     dots: true,
//     pauseOnHover: true,
//     pauseOnDotsHover: false,
//     autoplay: true,
//     autoplaySpeed: 5000,
//     // fade: true,
//     // cssEase: 'linear'
//   });
// }

$(".homeFeedBox4 span.readMore").text("Visit");

$("body.subsite .carousel .carouselSlide img").attr("src", function (i, src) {
  return src.replace("&w=965", "&w=1800");
});
$("body.subsite .carousel .carouselSlide img").attr("srcset", "");

$("body.subsite .post .carousel .carouselSlide img").attr("src", function (i, src) {
  return src.replace("&h=1200", "&h=900");
});

if ($('body.subsite .carouselSlide img').length) {
  $('body').addClass('hasImage');
}

$('a.subsite-button').on('click', function (e) {
  e.preventDefault();
  e.stopPropagation();
  $('iframe.typeform-popup').addClass('active');
});

$(document.body).click(function () {
  $('iframe.typeform-popup').removeClass('active');
});

$('body.subsite .subsiteFeedBox4.subsiteFeedsnippets .feedItem').each(function () {
  $(this).children('h3').addClass('feedTitle')
  $(this).children('iframe').wrapAll('<a class="home-feed-image-link" />');
});

// https://raisingit.atlassian.net/browse/IM-1019
$('.forum-carousel').slick({
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  infinite: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

$(document).ready(function() {
  $("body.page-has-banner.listing-without-feature .listingIntro").insertBefore(".listContent");
  $(".GlobalAlert ").insertBefore(".headerContent"); // 1372153 SA - move GlobalAlert banner to the top
});

